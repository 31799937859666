var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-element-group", {
    scopedSlots: _vm._u([
      {
        key: "col-1",
        fn: function() {
          return [
            _c("form-text-field", {
              class: {
                "mx-1": _vm.$vuetify.breakpoint.smAndDown
              },
              attrs: {
                label: "Rental Unit/Address Applying",
                name: "unit",
                disabled: "",
                placeholder: "Enter a rental unit",
                "hide-details": ""
              },
              on: {
                input: function($event) {
                  return _vm.$emit("update:meta", _vm.model)
                }
              },
              model: {
                value: _vm.model.unit,
                callback: function($$v) {
                  _vm.$set(_vm.model, "unit", $$v)
                },
                expression: "model.unit"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "col-2",
        fn: function() {
          return [
            _c("form-currency-text-field", {
              class: {
                "mx-1": _vm.$vuetify.breakpoint.smAndDown
              },
              attrs: {
                disabled: "",
                label: "Monthly Rent Amount",
                name: "rent",
                placeholder: "Enter monthly rent",
                "hide-details": ""
              },
              on: {
                input: function($event) {
                  return _vm.$emit("update:meta", _vm.model)
                }
              },
              model: {
                value: _vm.model.rent,
                callback: function($$v) {
                  _vm.$set(_vm.model, "rent", $$v)
                },
                expression: "model.rent"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }