<template>
    <!-- NOTE: Rent, Unit are captured at the invite/application creation step. Read Only Here -->
    <form-element-group>
        <template v-slot:col-1>
            <form-text-field
                :class="{
                    'mx-1': $vuetify.breakpoint.smAndDown,
                }"
                label="Rental Unit/Address Applying"
                name="unit"
                disabled
                placeholder="Enter a rental unit"
                v-model="model.unit"
                hide-details
                @input="$emit('update:meta', model)"
            ></form-text-field>
        </template>
        <template v-slot:col-2>
            <form-currency-text-field
                :class="{
                    'mx-1': $vuetify.breakpoint.smAndDown,
                }"
                disabled
                label="Monthly Rent Amount"
                name="rent"
                placeholder="Enter monthly rent"
                v-model="model.rent"
                hide-details
                @input="$emit('update:meta', model)"
            ></form-currency-text-field>
        </template>
    </form-element-group>
</template>
<script>
export default {
    name: "property-meta",
    components: {},
    props: {
        meta: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            model: Object.assign({}, this.meta),
        };
    },
};
</script>

<style scoped>
</style>